import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Config from '../../config';
import type { LoginResponse, UsernameResponse } from '../../types';
import {
  loggedInState,
  loginFormMessageState,
  loginFormPasswordState,
  loginFormUsernameState,
  userState,
} from '../Main/Store';
import LoginView from './Login-View';

const Login = () => {
  const setUser = useSetRecoilState(userState);
  const setLoggedIn = useSetRecoilState(loggedInState);
  const [loginFormUsername, setLoginFormUsername] = useRecoilState(
    loginFormUsernameState
  );
  const [loginFormPassword, setLoginFormPassword] = useRecoilState(
    loginFormPasswordState
  );
  const [loginFormMessage, setLoginFormMessage] = useRecoilState(
    loginFormMessageState
  );

  const getUserName = async () => {
    const res = await fetch(`${Config.API_URL}/working-time/username`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
    });
    console.log(res);
    if (res.ok) {
      const username = (await res.json()) as UsernameResponse;
      if (username.state === 1) {
        console.log('Failed');
        return false;
      }
      if (username.state === 0) {
        setUser(username.data.username);
        return true;
      }
    } else {
      return false;
    }
    return false;
  };

  const loginUser = async (username: String, password: String) => {
    const res = await fetch(`${Config.API_URL}/working-time/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        username: username as string,
        password: password as string,
      }),
      credentials: 'include',
    });
    console.log(res);
    if (res.ok) {
      const login = (await res.json()) as LoginResponse;
      if (login.state === 1) {
        console.log('Failed');
        setLoginFormMessage('Incorrect username/password combination.');
        return null;
      }
      if (login.state === 0) {
        setLoggedIn(true);
        // Reset everything
        setLoginFormMessage('');
        setLoginFormPassword('');
        setLoginFormUsername('');
        // Fetch username
        getUserName();
        return true;
      }
    } else if (res.status === 403) {
      setLoginFormMessage('Incorrect username/password combination.');
      return null;
    } else if (res.status === 406) {
      setLoginFormMessage('Incomplete');
      return null;
    } else {
      setLoginFormMessage('Connection issue');
      return null;
    }
    return null;
  };

  function handleSubmit() {
    loginUser(loginFormUsername, loginFormPassword);
  }

  return (
    <>
      <LoginView
        hide={false}
        message={loginFormMessage}
        submit={handleSubmit}
      />
    </>
  );
};

export default Login;
