import React from 'react';
import { useRecoilValue } from 'recoil';

import Logout from '../Logout';
import { userState } from '../Main/Store';

interface MenuViewProps {
  name: String;
}

const MenuView: React.FC<MenuViewProps> = () => {
  const user = useRecoilValue(userState);

  return (
    <>
      <nav>
        <div className="left-0 top-0 flex h-16 w-full flex-row justify-between bg-primary p-3">
          <h1 className="font-bold text-white">Working Time</h1>
          <p className="text-white">{user === '' ? '' : `Welcome, ${user}`}</p>
          <Logout />
        </div>
      </nav>
    </>
  );
};

export default MenuView;
