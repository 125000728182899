import React from 'react';

import type { Task } from '../../types';
import type { HorizontalDisplay, Segment } from '../../types/HorizontalDisplay';
import secondsToHumanReadable from '../../utils/secondsToHumanReadable';

interface LoginProps {
  hide: Boolean;
  dayTotal: HorizontalDisplay;
  tasks: Task[];
}

const TableDisplayView: React.FC<LoginProps> = ({ hide, dayTotal, tasks }) => {
  if (dayTotal.data === undefined || tasks.length === 0) {
    return <p>Loading...</p>;
  }

  function filterTasks(segment: Segment) {
    return tasks.find((task) => task.id === segment.taskId);
  }

  const listItems = dayTotal.data
    .filter((segment) => segment.taskId !== 0 && segment.taskId !== 1)
    .sort((a, b) => b.duration - a.duration)
    .map((segment) => (
      <tr key={segment.taskId}>
        <td className="border-b border-r border-primary pr-3 font-bold">
          {filterTasks(segment)?.name}
        </td>
        <td className="border-b border-l border-primary text-right">
          {secondsToHumanReadable(segment.duration)}
        </td>
      </tr>
    ));

  const result = dayTotal.data
    .filter((data) => data.taskId !== 0 && data.taskId !== 1)
    .reduce((total, currentValue) => total + currentValue.duration, 0);

  if (hide) {
    return null;
  }

  return (
    <>
      <div className="m-auto my-6 max-w-fit rounded-md border-2 border-primary shadow-lg">
        {/* <p className="p-1">Tasks: {selected.data.taskId}</p> */}
        <table className="m-2">
          <thead>
            <tr>
              <th className="border-b border-r border-primary px-3">Task</th>
              <th className="border-b border-primary px-3">Total Duration</th>
            </tr>
          </thead>
          <tbody>{listItems}</tbody>
          <tfoot>
            <tr className="border-t-2 border-primary">
              <td className="border-r border-primary">Total</td>
              <td className="text-right">{secondsToHumanReadable(result)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default TableDisplayView;
