import Cookies from 'js-cookie';

import config from '../config';
import type {
  ColorResponse,
  Record,
  RecordResponse,
  Response,
  Selected,
  Task,
} from '../types';
import type {
  HorizontalDisplay,
  HorizontalDisplayResponse,
} from '../types/HorizontalDisplay';

export async function getRecord(id: number) {
  const params = new URLSearchParams();
  params.set('id', id.toString());
  const res = await fetch(
    `${config.API_URL}/working-time/record?${params.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string,
      },
      credentials: 'include',
    }
  );
  if (res.ok) {
    const response = (await res.json()) as Response<Record>;
    if (response.state === 0) {
      return response.data;
    }
    return null;
  }
  return null;
}

export async function postRecord(): Promise<RecordResponse | null> {
  const params = new URLSearchParams();
  params.set('time', new Date().toISOString().slice(0, 19).replace('T', ' '));
  params.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

  const res = await fetch(
    `${config.API_URL}/working-time/record?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string,
      },
      credentials: 'include',
    }
  );
  if (res.ok) {
    const record = (await res.json()) as RecordResponse;
    if (record.state === 1) {
      console.log('Failed');
      return null;
    }
    if (record.state === 0) {
      return record;
    }
  } else {
    return null;
  }
  return null;
}

export async function putRecord(
  id: number,
  start: Date,
  end?: Date
): Promise<Response<{ this: Record; next: Record | null }> | null> {
  const params = new URLSearchParams();
  params.set('id', id.toString());
  params.set('start', start.toISOString().slice(0, 19).replace('T', ' '));
  if (end) {
    params.set('end', end.toISOString().slice(0, 19).replace('T', ' '));
  }

  const res = await fetch(
    `${config.API_URL}/working-time/record?${params.toString()}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string,
      },
      credentials: 'include',
    }
  );
  if (res.ok) {
    const response = await res.json();
    if (response.state === 0) {
      return response.data;
    }
    return null;
  }
  return null;
}

export async function getTasks() {
  const res = await fetch(`${config.API_URL}/working-time/task`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
  });
  if (res.ok) {
    const tasks = (await res.json()) as Response<Task[]>;
    if (tasks.state === 1) {
      console.log('Failed');
      return null;
    }
    if (tasks.state === 0) {
      return tasks.data;
    }
  } else {
    return null;
  }
  return null;
}

export async function postTasks(id: string, name: string) {
  const res = await fetch(`${config.API_URL}/working-time/task`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string,
    },
    body: new URLSearchParams({
      name,
      colorId: id,
    }),
    credentials: 'include',
  });
  if (res.ok) {
    const tasks = (await res.json()) as Response<Task[]>;
    if (tasks.state === 1) {
      console.log('Failed');
      return null;
    }
    if (tasks.state === 0) {
      return tasks.data;
    }
  } else {
    return null;
  }
  return null;
}

export async function getSelect() {
  const res = await fetch(`${config.API_URL}/working-time/selected`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
  });
  if (res.ok) {
    const selected = (await res.json()) as Selected;
    if (selected.state === 1) {
      console.log('Failed');
      return null;
    }
    if (selected.state === 0) {
      return selected;
    }
  } else {
    return null;
  }
  return null;
}

export async function postSelect(id: string) {
  const res = await fetch(`${config.API_URL}/working-time/selected`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string,
    },
    body: new URLSearchParams({
      taskId: id,
    }),
    credentials: 'include',
  });
  if (res.ok) {
    const selected = (await res.json()) as Selected;
    if (selected.state === 1) {
      console.log('Failed');
      return null;
    }
    if (selected.state === 0) {
      postRecord();
      return selected;
    }
  } else {
    return null;
  }
  return null;
}

export async function getHorizontalDisplay(
  day: number,
  total?: boolean
): Promise<HorizontalDisplay | null> {
  const res = await fetch(`${config.API_URL}/working-time/construct-day`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string,
    },
    body: new URLSearchParams({
      tz_offset: new Date().getTimezoneOffset().toString(),
      day: day.toString(),
      total: total ? '1' : '0',
    }),
    credentials: 'include',
  });
  if (res.ok) {
    const overview = (await res.json()) as HorizontalDisplayResponse;
    if (overview.state === 1) {
      console.log('Failed');
      return null;
    }
    if (overview.state === 0) {
      return overview.data;
    }
  } else {
    return null;
  }
  return null;
}

export async function getColors() {
  const res = await fetch(`${config.API_URL}/working-time/color`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
  });
  if (res.ok) {
    const colors = (await res.json()) as ColorResponse;
    if (colors.state === 1) {
      console.log('Failed');
      return null;
    }
    if (colors.state === 0) {
      return colors;
    }
  } else {
    return null;
  }
  return null;
}
