export const getStartOfWeekTimestamp = (dayModifier: number) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0); // set to start of the day

  // adjust date by the given dayModifier
  date.setDate(date.getDate() + dayModifier);

  // adjust to the start of the week (Monday)
  // getDay() returns the day of the week (0-6, with 0 being Sunday)
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust to previous Monday

  date.setDate(diff);

  return Math.floor(date.getTime() / 1000);
};
