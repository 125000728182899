import { atom } from 'recoil';

import type {
  Color,
  ColorResponse,
  Segment,
  Selected,
  Task,
} from '../../types';
import type { HorizontalDisplay } from '../../types/HorizontalDisplay';

export const userState = atom({
  key: 'user',
  default: '' as String,
});

export const loggedInState = atom({
  key: 'loggedIn',
  default: null as Boolean | null,
});

export const loginFormUsernameState = atom({
  key: 'loginFormUsername',
  default: '' as String,
});

export const loginFormPasswordState = atom({
  key: 'loginFormPassword',
  default: '' as String,
});

export const loginFormMessageState = atom({
  key: 'loginFormMessage',
  default: '' as String,
});

export const tasksState = atom({
  key: 'tasks',
  default: [] as Task[],
});

export const selectedState = atom({
  key: 'selected',
  default: { data: {} } as Selected,
});

export const dayState = atom({
  key: 'dayState',
  default: {} as HorizontalDisplay,
});

export const dayTotalState = atom({
  key: 'dayTotalState',
  default: {} as HorizontalDisplay,
});

export const overviewScaleState = atom({
  key: 'overviewScale',
  default: 1 as number,
});

export const showAddTaskState = atom({
  key: 'showAddTask',
  default: false as boolean,
});

export const colorsState = atom({
  key: 'colors',
  default: { data: {} } as ColorResponse,
});

export const colorState = atom({
  key: 'color',
  default: {
    dateAdded: 1,
    fontWhite: true,
    hex: '16cc3b',
    id: 1,
    name: 'green',
  } as Color,
});

export const addTaskFormNameState = atom({
  key: 'addTaskFormName',
  default: '' as string,
});

export const dayModifierState = atom({
  key: 'dayModifier',
  default: 0,
});

export const editRecordState = atom({
  key: 'editRecordState',
  default: [null, null] as [Segment | null, Segment | null],
});
