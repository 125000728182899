import React from 'react';
import { useRecoilValue } from 'recoil';

import secondsToHumanReadable from '../../utils/secondsToHumanReadable';
import { tasksState } from '../Main/Store';

interface LoginProps {
  hide: Boolean;
  weekTotal: { [key: number]: number };
  date: number;
}

const WeeklyDisplayView: React.FC<LoginProps> = ({ hide, weekTotal, date }) => {
  const tasks = useRecoilValue(tasksState);

  if (Object.keys(weekTotal).length === 0 || tasks.length === 0) {
    return <p>Loading...</p>;
  }

  function filterTask(taskId: number) {
    return tasks.filter((task) => task.id === taskId)[0];
  }

  const listItems = Object.keys(weekTotal)
    .sort((a, b) => weekTotal[Number(b)] - weekTotal[Number(a)])
    .filter((i) => i !== '0' && i !== '1')
    .map((taskId) => (
      <tr key={taskId}>
        <td className="border-b border-r border-primary pr-3 font-bold">
          {filterTask(Number(taskId)).name}
        </td>
        <td className="border-b border-l border-primary text-right">
          {secondsToHumanReadable(weekTotal[Number(taskId)])}
        </td>
      </tr>
    ));

  const result = Object.entries(weekTotal)
    .filter(([k]) => k !== '0' && k !== '1')
    .reduce((total, [_k, v]) => total + v, 0);

  if (hide) {
    return null;
  }

  return (
    <>
      <div className="m-auto my-6 w-fit max-w-full rounded-md border-2 border-primary shadow-lg">
        <label className="px-1">
          Weekly Total since {new Date(date * 1000).toDateString()}
        </label>
        <table className="mx-auto my-2">
          <thead>
            <tr>
              <th className="border-b border-r border-primary px-3">Task</th>
              <th className="border-b border-primary px-3">Total Duration</th>
            </tr>
          </thead>
          <tbody>{listItems}</tbody>
          <tfoot>
            <tr className="border-t-2 border-primary">
              <td className="border-r border-primary">Total</td>
              <td className="text-right">{secondsToHumanReadable(result)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default WeeklyDisplayView;
