import zeroPad from './zeroPad';

const secondsToHumanReadable = (seconds: number) => {
  return (
    <>
      {Math.floor(seconds / 60 / 60)}:
      {zeroPad(
        Number(
          (Math.floor(seconds) - Math.floor(seconds / 60 / 60) * 60 * 60) / 60
        ),
        2
      )}
      h
    </>
  );
};

export default secondsToHumanReadable;
