import React from 'react';
import { useRecoilState } from 'recoil';

import { loginFormPasswordState, loginFormUsernameState } from '../Main/Store';

interface LoginProps {
  hide: Boolean;
  message: String;
  submit: () => void;
}

const LoginView: React.FC<LoginProps> = ({ hide, message, submit }) => {
  const [loginFormUsername, setLoginFormUsername] = useRecoilState(
    loginFormUsernameState
  );
  const [loginFormPassword, setLoginFormPassword] = useRecoilState(
    loginFormPasswordState
  );

  if (hide) {
    return null;
  }

  return (
    <>
      <div className="m-auto mt-5 w-3/4 max-w-md content-center justify-center rounded-lg border border-gray-600 bg-primary p-4 align-middle md:w-1/2 lg:w-1/4">
        <div className="flex h-full w-full flex-col justify-center space-y-5 text-center align-middle">
          <input
            type="text"
            id="username"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Username"
            required
            onChange={(e) => setLoginFormUsername(e.target.value)}
          ></input>
          <input
            type="password"
            id="password"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Password"
            required
            onChange={(e) => setLoginFormPassword(e.target.value)}
          ></input>
          <p>{message}</p>
          <button
            disabled={loginFormUsername === '' || loginFormPassword === ''}
            className="text-white hover:text-gray-500 disabled:text-gray-500"
            onClick={() => submit()}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginView;
