import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { getColors, postTasks } from '../../utils/fetch';
import {
  addTaskFormNameState,
  colorsState,
  colorState,
  tasksState,
} from '../Main/Store';
import AddTaskView from './AddTask-view';

const AddTask: React.FC = () => {
  const setTasks = useSetRecoilState(tasksState);
  const [colors, setColors] = useRecoilState(colorsState);
  const setColor = useSetRecoilState(colorState);
  const [addTaskFormName, setAddTaskFormName] =
    useRecoilState(addTaskFormNameState);

  // useEffect(() => {
  //     postTasks();
  //   }, [loggedIn])

  useEffect(() => {
    getColors().then((res) => {
      if (res) {
        setColors(res);
      }
    });
  }, []);

  function handleClick(id: number) {
    postTasks(id.toString(), addTaskFormName).then((res) => {
      if (res) {
        setTasks(res);
      }
    });
    setAddTaskFormName('');
  }

  function handleChange(string: string) {
    setColor(colors.data.filter((color) => color.id === Number(string))[0]);
  }

  return (
    <AddTaskView
      hide={false}
      handleChange={handleChange}
      handleClick={handleClick}
    />
  );
};

export default AddTask;
