import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import type { Record } from '../../types';
import { getRecord, putRecord } from '../../utils/fetch';
import { editRecordState } from '../Main/Store';

const RecordEdit = () => {
  const [[segmentThis, segmentNext], setEditRecordState] =
    useRecoilState(editRecordState);
  const [recordThis, setRecordThis] = useState<Record | null>(null);
  const [thisTimeInput, setThisTimeInput] = useState<string | null>(null);
  const [nextTimeInput, setNextTimeInput] = useState<string | null>(null);

  useEffect(() => {
    if (!segmentThis || !segmentThis.id) return;
    getRecord(segmentThis.id).then((record) => {
      setRecordThis(record);
      if (record?.time) {
        const utcDate = new Date(record.time);
        setThisTimeInput(
          new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, 16)
        );
      }
    });

    if (segmentNext && segmentNext.id) {
      getRecord(segmentNext.id).then((record) => {
        if (record?.time) {
          const utcDate = new Date(record.time);
          setNextTimeInput(
            new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000)
              .toISOString()
              .slice(0, 16)
          );
        }
      });
    } else {
      setNextTimeInput(null);
    }
  }, [segmentNext, segmentThis]);

  const handleDateChangeThis = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThisTimeInput(event.target.value);
  };

  const handleDateChangeNext = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNextTimeInput(event.target.value);
  };

  const handleSubmit = async () => {
    if (recordThis) {
      const localStartDate = new Date(`${thisTimeInput!}:00`);
      const localEndDate = nextTimeInput
        ? new Date(`${nextTimeInput}:00`)
        : undefined;

      const utcStartDate = new Date(localStartDate.getTime());
      const utcEndDate = localEndDate
        ? new Date(localEndDate.getTime())
        : undefined;

      console.log(localStartDate, 'entered as ', utcStartDate);

      await putRecord(recordThis.id, utcStartDate, utcEndDate);
      window.location.reload();
    }
  };

  if (!segmentThis) {
    return <></>;
  }

  return (
    <div className="mx-auto w-72 rounded-md border-2 border-blue-500 p-1">
      <div className="flex justify-end">
        <button
          onClick={() => setEditRecordState([null, null])}
          className="w-fit border border-blue-500 px-2 py-1"
        >
          close
        </button>
      </div>
      {thisTimeInput ? (
        <div className="mt-3">
          <label className="pr-4">Start</label>
          <input
            type="datetime-local"
            value={thisTimeInput}
            onChange={handleDateChangeThis}
          />
        </div>
      ) : (
        <></>
      )}
      {nextTimeInput ? (
        <div className="my-3">
          <label className="pr-4">End</label>
          <input
            type="datetime-local"
            value={nextTimeInput}
            onChange={handleDateChangeNext}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="flex justify-center">
        <button
          onClick={handleSubmit}
          className="border border-blue-500 px-2 py-1"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default RecordEdit;
