import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getHorizontalDisplay } from '../../utils/fetch';
import { dayModifierState, dayState } from '../Main/Store';
import HorizontalDisplayView from './HorizontalDisplay-view';

const HorizontalDisplayContainer: React.FC = () => {
  const [day, setDay] = useRecoilState(dayState);
  const dayModifier = useRecoilValue(dayModifierState);

  useEffect(() => {
    const dayOffset = dayModifier * 60 * 60 * 24;
    getHorizontalDisplay(Math.floor(Date.now() / 1000) + dayOffset, false).then(
      (res) => {
        if (res) {
          setDay(res);
        }
      }
    );
  }, [dayModifier]);

  useEffect(() => {
    const interval = setInterval(() => {
      const dayOffset = dayModifier * 60 * 60 * 24;

      getHorizontalDisplay(
        Math.floor(Date.now() / 1000) + dayOffset,
        false
      ).then((res) => {
        if (res) {
          setDay(res);
        }
      });
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dayModifier]);

  if (!day) {
    return <div>Loading day...</div>;
  }

  return <HorizontalDisplayView hide={false} dayConstruct={day} />;
};

export default HorizontalDisplayContainer;
