import React from 'react';
import { useSetRecoilState } from 'recoil';

import { dayModifierState } from '../Main/Store';
import OptionsView from './Options-view';

const Options: React.FC = () => {
  const setDayModifier = useSetRecoilState(dayModifierState);

  function handleClick(increment: number) {
    setDayModifier(increment);
  }

  return <OptionsView hide={false} handleClick={handleClick} />;
};

export default Options;
