import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import type { HorizontalDisplay } from '../../types/HorizontalDisplay';
import { getHorizontalDisplay } from '../../utils/fetch';
import { getStartOfWeekTimestamp } from '../../utils/getStartOfWeekTimestamp';
import { dayModifierState } from '../Main/Store';
import WeeklyDisplayView from './WeeklyDisplay-view';

const WeekDisplay: React.FC = () => {
  const [weekTotal, setWeekTotal] = useState<{ [key: number]: number }>({});
  const [shouldLoad, setShouldLoad] = useState<boolean>(false);
  const dayModifier = useRecoilValue(dayModifierState);

  const mergeWeekData = (
    weekData: (HorizontalDisplay | null | undefined)[]
  ) => {
    const mergedData: { [key: number]: number } = {};

    weekData.forEach((dayData) => {
      if (dayData !== null && dayData !== undefined) {
        dayData.data.forEach((segment) => {
          if (segment.taskId in mergedData) {
            mergedData[segment.taskId] += segment.duration;
          } else {
            mergedData[segment.taskId] = segment.duration;
          }
        });
      }
    });

    return mergedData;
  };

  const getWeekDisplay = async (startOfWeekTimestamp: number) => {
    const weekDataPromises = Array(7)
      .fill(null)
      .map((_, dayIndex) => {
        return getHorizontalDisplay(
          startOfWeekTimestamp + dayIndex * 60 * 60 * 24
        );
      });

    const weekData = await Promise.all(weekDataPromises);

    const mergedData = mergeWeekData(
      weekData.filter((data) => data !== undefined) as HorizontalDisplay[]
    );

    setWeekTotal(mergedData);

    return mergedData;
  };

  useEffect(() => {
    if (shouldLoad) {
      getWeekDisplay(getStartOfWeekTimestamp(dayModifier));
    }
  }, [dayModifier, shouldLoad]);

  if (!shouldLoad) {
    return (
      <div className="flex justify-center">
        <button
          onClick={() => setShouldLoad(true)}
          className="rounded-sm border border-blue-500 p-2"
        >
          load weekly view
        </button>
      </div>
    );
  }

  return (
    <WeeklyDisplayView
      hide={false}
      weekTotal={weekTotal}
      date={getStartOfWeekTimestamp(dayModifier)}
    />
  );
};

export default WeekDisplay;
