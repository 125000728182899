import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Config from '../../config';
import type { UsernameResponse } from '../../types';
import HorizontalDisplay from '../HorizontalDisplay';
import Login from '../Login';
import Menu from '../Menu';
import Options from '../Options';
import RecordEdit from '../RecordEdit/RecordEdit';
import TableDisplay from '../TableDisplay';
import TaskSelect from '../TaskSelect';
import WeekDisplay from '../WeekDisplay/WeekDisplay';
import { loggedInState, userState } from './Store';

const Main: React.FC = () => {
  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
  const setUser = useSetRecoilState(userState);

  const checkConnection = async () => {
    const res = await fetch(`${Config.API_URL}/working-time/username`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
    });
    if (res.ok) {
      const username = (await res.json()) as UsernameResponse;
      if (username.state === 1) {
        console.log('Failed');
        return false;
      }
      if (username.state === 0) {
        setLoggedIn(true);
        setUser(username.data.username);
        return true;
      }
    } else if (res.status === 401) {
      setLoggedIn(false);
    } else {
      return false;
    }
    return false;
  };

  useEffect(() => {
    checkConnection();
  }, []);

  if (loggedIn === null) {
    return (
      <>
        <Menu />
        {checkConnection}
        <p>Logging in...</p>
      </>
    );
  }
  if (loggedIn) {
    return (
      <>
        <Menu />
        <TaskSelect />
        <Options />
        <HorizontalDisplay />
        <RecordEdit />
        <TableDisplay />
        <WeekDisplay />
      </>
    );
  }
  return (
    <>
      <Menu />
      <Login />
    </>
  );
};

export default Main;
