import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { addTaskFormNameState, colorsState, colorState } from '../Main/Store';

interface LoginProps {
  hide: Boolean;
  handleChange: (string: string) => void;
  handleClick: (id: number) => void;
}

const AddTaskView: React.FC<LoginProps> = ({
  hide,
  handleChange,
  handleClick,
}) => {
  const [addTaskFormName, setAddTaskFormName] =
    useRecoilState(addTaskFormNameState);
  const colors = useRecoilValue(colorsState);
  const color = useRecoilValue(colorState);

  if (hide) {
    return null;
  }

  if (colors.state === undefined) {
    return null;
  }

  const dropdown = colors.data.map((c) => (
    <option value={c.id} key={c.id}>
      {c.name}
    </option>
  ));

  return (
    <>
      <div className="my-auto flex max-w-[50%] rounded-md border border-primary bg-slate-200 p-1 shadow-lg">
        <input
          type="text"
          id="username"
          className="mr-2 block w-2/3 rounded-lg border border-gray-300 bg-gray-50 p-1 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder="Name"
          required
          onChange={(e) => setAddTaskFormName(e.target.value)}
        ></input>
        <div
          style={{ backgroundColor: `#${color.hex}` }}
          className="m-auto h-9 w-9 rounded-md border border-primary"
        ></div>
        <label>
          <select
            className="mx-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            value={color.id}
            onChange={(e) => handleChange(e.target.value)}
          >
            {dropdown}
          </select>
        </label>
        <button
          className="ml-4 mr-1 rounded-md border-2 p-1 hover:border-primary disabled:text-slate-300"
          onClick={() => handleClick(color.id)}
          disabled={addTaskFormName.length === 0}
        >
          Add
        </button>
      </div>
    </>
  );
};

export default AddTaskView;
