import './App.css';

import React from 'react';
import { RecoilRoot } from 'recoil';

import Main from './components/Main';

function App() {
  return (
    <RecoilRoot>
      <Main />
    </RecoilRoot>
  );
}

export default App;
