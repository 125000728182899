import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import type { Task } from '../../types';
import { getSelect, getTasks, postSelect } from '../../utils/fetch';
import {
  loggedInState,
  selectedState,
  showAddTaskState,
  tasksState,
} from '../Main/Store';
import TaskSelectView from './TaskSelect-view';

const TaskSelect: React.FC = () => {
  const [tasks, setTasks] = useRecoilState(tasksState);
  const setSelected = useSetRecoilState(selectedState);
  const loggedIn = useRecoilValue(loggedInState);
  const [showAddTask, setShowAddTask] = useRecoilState(showAddTaskState);

  useEffect(() => {
    getTasks().then((res) => {
      if (res) {
        setTasks(res);
      }
    });
  }, [loggedIn]);

  useEffect(() => {
    getSelect().then((res) => {
      if (res) {
        setSelected(res);
      }
    });
  }, [tasks]);

  function handleClick(id: Task['id']) {
    postSelect(id.toString()).then((res) => {
      if (res) {
        setSelected(res);
      }
    });
  }

  function handleClickAdd() {
    setShowAddTask(!showAddTask);
  }

  return (
    <TaskSelectView
      hide={false}
      handleClick={handleClick}
      handleClickAdd={handleClickAdd}
    />
  );
};

export default TaskSelect;
