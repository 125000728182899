import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import windowDimensions from '../../utils/useWindowDimensions';
import zeroPad from '../../utils/zeroPad';
import { dayModifierState, dayState, overviewScaleState } from '../Main/Store';

interface LoginProps {
  hide: Boolean;
  handleClick: (increment: number) => void;
}

const OptionsView: React.FC<LoginProps> = ({ hide, handleClick }) => {
  const day = useRecoilValue(dayState);
  const [scale, setScale] = useRecoilState(overviewScaleState);
  const dayModifier = useRecoilValue(dayModifierState);
  const { width } = windowDimensions();

  const date = new Date();
  date.setDate(date.getDate() + dayModifier);

  if (day.data === undefined) {
    return <p>Loading...</p>;
  }

  const hourSeparators = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];

  hourSeparators.map((elem) => (
    <div
      style={{
        width: (width / 24) * scale,
      }}
      className="flex w-full shrink-0 grow border-b border-r-2  border-black"
      key={elem}
    >
      <p className="w-full">
        {zeroPad(elem, 2)}
        {width * scale > 1080 ? ':00' : ''}
      </p>
    </div>
  ));

  if (hide) {
    return null;
  }

  const scaleSelector = [1, 2, 3, 4, 8, 24].map((option) => (
    <button
      className={`${
        option === scale ? 'border-2' : 'border'
      } m-1  rounded-sm border-primary bg-slate-100 px-2 py-1`}
      onClick={() => setScale(option)}
      key={option}
    >
      {24 / option}h
    </button>
  ));

  return (
    <div className="flex flex-row place-content-between items-center">
      <div className="px-2">Scale: {scaleSelector}</div>
      <div className="mr-4 flex items-center font-mono">
        <span>{date.toDateString()}</span>
        <button
          className={
            'm-1 rounded-sm  border border-primary bg-slate-100 px-2 py-1'
          }
          onClick={() => {
            handleClick(dayModifier - 1);
          }}
        >
          {'<'}
        </button>
        <button
          className={
            'm-1 rounded-sm  border border-primary bg-slate-100 px-2 py-1'
          }
          onClick={() => {
            handleClick(0);
          }}
        >
          {dayModifier}
        </button>
        <button
          className={
            'm-1 rounded-sm border border-primary bg-slate-100 px-2 py-1 disabled:text-slate-400'
          }
          onClick={() => {
            handleClick(dayModifier + 1);
          }}
          disabled={dayModifier >= 0}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default OptionsView;
