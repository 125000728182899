import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getHorizontalDisplay } from '../../utils/fetch';
import { dayModifierState, dayTotalState, tasksState } from '../Main/Store';
import TableDisplayView from './TableDisplay-view';

const TableDisplay: React.FC = () => {
  const tasks = useRecoilValue(tasksState);
  const [dayTotal, setDayTotal] = useRecoilState(dayTotalState);
  const dayModifier = useRecoilValue(dayModifierState);

  useEffect(() => {
    const dayOffset = dayModifier * 60 * 60 * 24;
    getHorizontalDisplay(Math.floor(Date.now() / 1000) + dayOffset, true).then(
      (res) => {
        if (res) {
          setDayTotal(res);
        }
      }
    );
  }, [dayModifier]);

  useEffect(() => {
    const interval = setInterval(() => {
      const dayOffset = dayModifier * 60 * 60 * 24;

      getHorizontalDisplay(
        Math.floor(Date.now() / 1000) + dayOffset,
        false
      ).then((res) => {
        if (res) {
          setDayTotal(res);
        }
      });
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dayModifier]);

  return <TableDisplayView hide={false} dayTotal={dayTotal} tasks={tasks} />;
};

export default TableDisplay;
