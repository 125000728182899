import React from 'react';
import { useRecoilValue } from 'recoil';

import type { Task } from '../../types';
import AddTask from '../AddTask';
import { selectedState, showAddTaskState, tasksState } from '../Main/Store';

interface LoginProps {
  hide: Boolean;
  handleClick: (id: Task['id']) => void;
  handleClickAdd: () => void;
}

const TaskSelectView: React.FC<LoginProps> = ({
  hide,
  handleClick,
  handleClickAdd,
}) => {
  const tasks = useRecoilValue(tasksState);
  const selected = useRecoilValue(selectedState);
  const showAddTask = useRecoilValue(showAddTaskState);

  if (tasks.length === 0) {
    const listItemsSkeleton = [
      'ABC',
      'DEFG',
      'HIJKLMN',
      'OP',
      'QRS',
      'TUV',
      'W',
      'XYZ',
      'ABCDEFG',
      'HIJKL',
      'MNOP',
      'QRSTUV',
      'WX',
      'YZ',
    ].map((task) => (
      <button
        className="m-2 cursor-pointer rounded-lg border border-black bg-black p-2 text-black disabled:cursor-not-allowed"
        key={task}
      >
        {task}
      </button>
    ));
    return (
      <div className="m-auto my-2 w-full rounded-md border-2 border-primary p-1 shadow-lg">
        <p className="p-1">Tasks:</p>
        <div className="m-2 flex flex-row flex-wrap">{listItemsSkeleton}</div>
      </div>
    );
  }

  const prepareList = [
    ...tasks
      .filter((task) => task.id > 1)
      .sort((a, b) => a.name.localeCompare(b.name as string)),
    ...tasks.filter((task) => task.id === 1),
  ];

  const listItems = prepareList.map((task) => (
    <button
      style={{ backgroundColor: `#${task.hex}` as string }}
      className={`${
        task.id === selected.data.taskId ? 'border-4' : 'border'
      } m-2 cursor-pointer rounded-lg border-black p-2 disabled:cursor-not-allowed ${
        task.fontWhite ? 'text-white' : 'text-black'
      }`}
      onClick={() => handleClick(task.id)}
      disabled={task.id === selected.data.taskId}
      key={task.id}
    >
      {task.name}
    </button>
  ));

  const addButton = (
    <button className={'p-3 text-black'} onClick={() => handleClickAdd()}>
      {!showAddTask ? '+' : 'x'}
    </button>
  );

  const selectedItem = tasks
    .filter((task) => task.id === selected.data.taskId)
    .map((task) => (
      <button
        style={{ backgroundColor: `#${task.hex}` as string }}
        className={`mx-2 my-1 rounded-lg border-2 border-black px-10 py-1 ${
          task.fontWhite ? 'text-white' : 'text-black'
        }`}
        onClick={() => handleClick(task.id)}
        disabled={task.id === selected.data.taskId}
        key={task.id}
      >
        {task.name}
      </button>
    ));

  if (hide) {
    return null;
  }

  return (
    <>
      <div className="m-auto my-2 max-w-fit rounded-md border-2 border-primary p-1 shadow-lg">
        <p className="p-1">Tasks:</p>
        <div className="m-2 flex flex-row flex-wrap">
          {listItems} {addButton} {showAddTask ? <AddTask /> : ''}
        </div>
      </div>
      <div className="m-auto mb-3 max-w-fit rounded-md border-2 border-primary p-1 shadow-lg">
        <p className="px-5">
          Writing:
          {selectedItem}
        </p>
      </div>
    </>
  );
};

export default TaskSelectView;
