import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { Segment } from '../../types';
import type { HorizontalDisplay } from '../../types/HorizontalDisplay';
import windowDimensions from '../../utils/useWindowDimensions';
import zeroPad from '../../utils/zeroPad';
import { editRecordState, overviewScaleState, tasksState } from '../Main/Store';

interface LoginProps {
  hide: Boolean;
  dayConstruct: HorizontalDisplay;
}

const HorizontalDisplayView: React.FC<LoginProps> = ({
  hide,
  dayConstruct,
}) => {
  const tasks = useRecoilValue(tasksState);
  const scale = useRecoilValue(overviewScaleState);
  const setEditRecord = useSetRecoilState(editRecordState);
  const { width } = windowDimensions();

  function CalculateWidth(seed: number) {
    const secondsInDay = 60 * 60 * 24;
    return width * (seed / secondsInDay) * scale;
  }

  const segments = dayConstruct.data;

  if (segments === undefined || segments.length === 0 || tasks.length === 0) {
    return <p>Loading...</p>;
  }

  function filterTask(segment: Segment) {
    return tasks.filter((task) => task.id === segment.taskId)[0];
  }

  if (filterTask === undefined) {
    return <p>Loading...</p>;
  }

  const listItems = segments.map((segment, index) => (
    <button
      style={{
        minWidth: 0,
        width: CalculateWidth(segment.duration),
        backgroundColor: `#${filterTask(segment).hex}`,
      }}
      className="shrink-0 text-clip"
      key={segment.id}
      onClick={() =>
        segment.id
          ? setEditRecord([segment, segments.at(index + 1) ?? null])
          : setEditRecord([null, null])
      }
    >
      <div
        style={{ color: filterTask(segment).fontWhite ? '#FFFFFF' : '#000000' }}
        className="h-full w-full border border-transparent py-4 hover:border-dashed hover:border-black"
      >
        <p>{filterTask(segment).id > 1 ? filterTask(segment).name : ''}</p>
        <p className="text-clip">{segment.duration / 60} min</p>
      </div>
    </button>
  ));

  const hourSeparators = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ].map((elem) => (
    <div
      style={{
        width: (width / 24) * scale,
      }}
      className="flex w-full shrink-0 grow border-b border-r-2  border-black"
      key={elem}
    >
      <p className="w-full">
        {zeroPad(elem, 2)}
        {width * scale > 1080 ? ':00' : ''}
      </p>
    </div>
  ));

  if (hide) {
    return null;
  }

  return (
    <div className="m-auto my-2 max-w-fit border-y-4 border-primary shadow-lg">
      {/* <p className="p-1">Tasks: {selected.data.taskId}</p> */}
      <div className="overflow-x-auto">
        <div className="flex shrink-0">{hourSeparators}</div>
        <div className="flex shrink-0 ">{listItems}</div>
      </div>
    </div>
  );
};

export default HorizontalDisplayView;
