import React from 'react';
import { useRecoilValue } from 'recoil';

import { loggedInState } from '../Main/Store';

interface LogoutProps {
  hide: Boolean;
  submit: () => void;
}

const LogoutView: React.FC<LogoutProps> = ({ submit }) => {
  const loggedIn = useRecoilValue(loggedInState);
  if (loggedIn !== true) {
    return null;
  }
  return (
    <>
      <button
        className="rounded-lg border border-white bg-white p-1 px-4 text-right align-middle text-primary hover:bg-blue-100"
        onClick={() => {
          submit();
        }}
      >
        Logout
      </button>
    </>
  );
};

export default LogoutView;
