import Cookies from 'js-cookie';
import React from 'react';
import { useSetRecoilState } from 'recoil';

import Config from '../../config';
import type { LoginResponse } from '../../types';
import { loggedInState, userState } from '../Main/Store';
import LogoutView from './Logout-View';

const Login: React.FC = () => {
  const setLoggedIn = useSetRecoilState(loggedInState);
  const setUser = useSetRecoilState(userState);

  const logoutUser = async () => {
    const res = await fetch(`${Config.API_URL}/working-time/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string,
      },
      credentials: 'include',
    });
    if (res.ok) {
      const login = (await res.json()) as LoginResponse;
      if (login.state === 1) {
        console.log('Failed');
        return false;
      }
      if (login.state === 0) {
        setLoggedIn(false);
        setUser('');
        return true;
      }
    } else {
      return false;
    }
    return false;
  };

  function handleSubmit() {
    logoutUser();
  }

  return (
    <>
      <LogoutView hide={false} submit={handleSubmit} />
    </>
  );
};

export default Login;
